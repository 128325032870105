/* Essential root variables for theme switching */
:root {
  --background-default: linear-gradient(135deg, #F5F7FA 0%, #E3EFFF 50%, #D1E0FF 100%);
  --text-primary: #1A2A44;
  --accent-blue: #00A3FF;
}

/* Reset and full-width essentials */
html, body, #root {
  margin: 0;
  padding: 0;
  width: 100%;
  height: 100%;
  overflow-x: hidden;
}

body {
  display: flex;
  flex-direction: column;
  background: var(--background-default);
}

/* Ensure first child div is full-width */
body > div:first-child {
  width: 100%;
}

/* Full-width app and content */
.app,
.content {
  width: 100%;
  margin: 0;
  padding: 0;
}

/* Sidebar transition */
:global(.sidebar-transition) {
  transition: width 0.3s ease-out !important;
}

/* TextField customizations */
.MuiTextField-root {
  width: 100%;
  margin-bottom: 16px;
}

.MuiInputLabel-root {
  font-family: 'Poppins', sans-serif;
  font-size: 0.9rem;
  font-weight: 500;
  color: var(--text-primary);
}

.MuiInputLabel-root.Mui-focused,
.MuiInputLabel-root.MuiFormLabel-filled {
  color: var(--accent-blue);
}

.MuiInputBase-root {
  font-family: 'Poppins', sans-serif;
  font-size: 1rem;
  color: var(--text-primary);
  background: rgba(255, 255, 255, 0.1);
  border-radius: 8px;
}

.MuiInputBase-root:hover {
  background: rgba(255, 255, 255, 0.2);
}

.MuiInputBase-root.Mui-focused {
  background: rgba(255, 255, 255, 0.3);
}

.MuiOutlinedInput-root:hover .MuiOutlinedInput-notchedOutline {
  border-color: var(--accent-blue);
}

.MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline {
  border-color: var(--accent-blue);
  border-width: 2px;
}

/* Dark mode adjustments */
body[data-theme="dark"] {
  --background-default: linear-gradient(135deg, #1A1A1A 0%, #2A2A2A 50%, #3A3A3A 100%);
  --text-primary: #E0E7FF;
}

body[data-theme="dark"] .MuiInputBase-root {
  background: rgba(255, 255, 255, 0.05);
}

body[data-theme="dark"] .MuiInputBase-root:hover {
  background: rgba(255, 255, 255, 0.1);
}

body[data-theme="dark"] .MuiInputBase-root.Mui-focused {
  background: rgba(255, 255, 255, 0.15);
}